<template>
  <el-card>
    <div class="title">Hostess Income</div>
    <el-row class="search">
      <el-input
        type="text"
        v-model.trim="search.hostId"
        placeholder="Hostess ID"
      />
      <el-date-picker
        v-model="search.date"
        type="daterange"
        align="right"
        unlink-panels
        :picker-options="retainedOptions"
        value-format="yyyy-MM-dd"
      >
      </el-date-picker>
      <el-button type="primary" @click="query()" :loading="loading"
        >Search</el-button
      >
    </el-row>
    <el-row>
      <el-table
        :data="list"
        style="width: 100%"
        v-loading="loading"
        stripe
        border
        element-loading-text="loading"
        element-loading-spinner="el-icon-loading"
      >
        <el-table-column prop="date" label="Date"></el-table-column>
        <el-table-column prop="agentId" label="Agency ID"></el-table-column>
        <el-table-column prop="id" label="Hostess ID"></el-table-column>
        <el-table-column prop="name" label="Name"></el-table-column>
        <el-table-column prop="country" label="Country"></el-table-column>
        <el-table-column prop="level" label="Quality level"></el-table-column>
        <el-table-column
          prop="onlineTime"
          label="Online duration"
        ></el-table-column>
        <el-table-column
          prop="liveTime"
          label="Live duration"
        ></el-table-column>
        <el-table-column prop="calls" label="Calls"></el-table-column>
        <el-table-column prop="callRate" label="Success Rate"></el-table-column>
        <el-table-column
          prop="chatTime"
          label="Total Call Duration(S)"
        ></el-table-column>
        <el-table-column prop="income" label="Income"></el-table-column>
        <el-table-column
          prop="estimateIncome"
          label="Revenue estimate（USD)"
        ></el-table-column>
      </el-table>
    </el-row>
    <el-row style="overflow: auto">
      <el-pagination
        @current-change="handleCurrent"
        :current-page="pageInfo.page"
        :page-size="30"
        layout="total,prev, pager, next, jumper"
        :total="pageInfo.total"
      >
      </el-pagination>
    </el-row>
  </el-card>
</template>

<script>
import { getHostessIncome } from '@/api/report.js'
import { datainfor } from '@/utils/formdata.js'
export default {
  data() {
    return {
      loading: false,
      retainedOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      },
      list: [],
      search: {
        date: [datainfor(1), datainfor(1)],
        hostId: ''
      },
      pageInfo: {
        total: 0,
        page: 1
      }
    }
  },
  created() {
    this.query()
  },
  methods: {
    handleCurrent(newPage) {
      this.pageInfo.page = newPage
      this.query()
    },
    // 获取列表
    async query() {
      const { data: res } = await getHostessIncome({
        start: this.search.date[0],
        end: this.search.date[1],
        hostId: this.search.hostId,
        page: this.pageInfo.page
      })
      if (res.code !== 0)
        return this.$message({
          message: res.msg,
          center: true,
          type: 'error'
        })
      this.$message({
        message: res.msg,
        center: true,
        type: 'success'
      })
      this.list = res.data.data
      this.pageInfo.total = res.data.total
    }
  }
}
</script>
