import request from '@/utils/request'

// export function getTotalIncome(params) {
//     return request({
//         url: '/agent_admin/report/total_income',
//         method: 'get',
//         params
//     })
// }

export function getTotalIncome(params) {
  return request({
    url: '/agent_admin/report/total_income/new',
    method: 'get',
    params
  })
}

export function getHostessIncome(params) {
  return request({
    url: 'agent_admin/host/report',
    method: 'get',
    params
  })
}
